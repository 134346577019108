import React from "react";
import ContactInfo from "../components/ContactInfo";
import ContactForm from "../components/ContactForm";
import contactHand from "../assets/images/kapcsolat.webp"
import name from '../assets/icons/name.webp'
import mail from '../assets/icons/mail.webp'
import tel from '../assets/icons/tel.webp'
import address from '../assets/icons/address.webp'


const Contact = React.forwardRef((props, ref) => {
  const contactData = [
    {label: "Név", value: "Balog Lóránd", type: "text", icon: name},
    {label: "Tel", value: "+36709429950", type: "phone", icon: tel},
    {label: "E-mail", value: "info@lorz.hu", type: "email", icon: mail},
    {label: "Cím", value: "3525 Miskolc, Bodótetői út 1.", type: "text", icon: address}
  ];

  return (
    <section id="contact" ref={ref}>
      <div className="contact-container">
        <article className="contact-header">
          <h1 className="header-title">Kapcsolat</h1>
        </article>
        <div className="contact-content-container">
          <ContactInfo data={contactData} />
          <ContactForm />
          <div className="contact-image-container">
            <img src={contactHand} alt="contact hand"/>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Contact;
