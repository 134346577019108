import hands_v2 from '../assets/images/kezek2.webp'
import tag from '../assets/images/header_person.webp'
import React from 'react';


const AboutUs = React.forwardRef((props, ref) => {

  const { contactRef } = props;

  
  const handleButtonClick = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section id="about-us" ref={ref}> 
        <div className='section-container'>
          <div className='content-wrapper'>
            <div className='full-width-background'>
              <div className='content-block-wrapper'>
                <article className='content-block'>
                  <h1 className='headline'>Hagyj mély nyomot a digitális térben!</h1>
                  <div className="section-separator orange"></div>
                  <h2 className='subhead'>A LORZ és csapata egyedülálló és lenyűgöző online élményeket teremt, hogy vállalkozásod a csúcsra törhessen!</h2>
                  <button className='cta-button' onClick={handleButtonClick}>Tovább</button>
                  <img className="mobile-image" src={tag} alt='tag' />
                </article>
                <figure className='illustration-block'>
                  <img src={hands_v2} alt='hands' />
                </figure>
              </div>
            </div>
            <div className='full-width-background'>
              <div className='content-block-wrapper'>
              <figure className='illustration-block'>
                  <img src={tag} alt='tag' />
                  </figure>
                <article className='content-block'>
                  <h2>Fedezd fel a végtelen lehetőségeket és számíts ránk a siker útján!</h2>
                  <h3>Tapasztalt és képzett szakembereink mindig itt vannak, hogy támogassanak és tanácsot adjanak elképzeléseidhez. A megbízható szoftverfejlesztési megoldások kiválasztásával valóra váltjuk személyre szabott elképzeléseidet. Minőségre törekedünk, és 100% garanciával állunk rendelkezésedre már több mint 10 éve. Fedezd fel a hatékonyabb fejlesztést velünk!</h3>
                </article>
                
              </div>
            </div>
          </div>
        </div>
    </section>
  )
})

export default AboutUs
