import React from "react";
import Card from "../components/Card";
import packages1 from "../assets/icons/package_1.webp";
import packages2 from "../assets/icons/package_2.webp";
import packages3 from "../assets/icons/package_3.webp";

const Packages = React.forwardRef((props, ref) => {
  
  return (
    <section id="packages" ref={ref}>
      <div className="packages-container">
        <div className="packages-content">
          <article className="packages-header">
            <h1 className="header-title">Csomagok</h1>
            <div className="section-separator orange"></div>
          </article>
          <div className="packages-cards">
            <Card
              logo={packages1}
              title="Teljeskörű weboldal támogatás"
              description="Csomagunk magában foglalja a tervezést, fejlesztést, karbantartást, reszponzivitást és SEO-optimalizációt, hogy a vállalkozásod weboldala mindig naprakész lehessen. Ha szeretnéd, hogy leendő partnereid, vásárlóid könnyen megtaláljanak és ki szeretnél emelkedni a versenytársak közül, válaszd ezt a csomagot!"
            />
            <Card
              logo={packages2}
              title="KKV megoldások"
              description="Kis- és középvállalatoknak szánt csomagunkat neked találták ki, ha gördülékenyebbé tennéd az ügyfélkapcsolataidat, az értékesítési folyamatokat és szeretnél többet tudni vevőidről és mellette még a termelést, értékesítést is jobban átláthatóvá, tervezhetőbbé tennéd! Megrendelőink számára egyedi CRM rendszert és termelésirányítási szoftvert kínálunk, ami a vállalkozásod igényeire szabva teszi könnyebbé munkádat, hogy jobban fókuszálhass a céljaidra!"
            />
            <Card
              logo={packages3}
              title="Egyedi webshop készítés"
              description="Pörgesd fel az értékesítést az online térben, hogy vevőid bárhonnan, bármikor megtaláljanak! Válaszd e-kereskedelmi csomagunkat, ha még nincs saját webáruházad vagy a már meglévőt alakítanád át teljesen. Csomagunk tartalmazza a webáruház technológiai és tartalmi fejlesztését, tesztelését és a termékek feltöltését. Népszerűsítenéd vállalkozásod és növeld vásárlóid elégedettségét? Segítünk!"
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Packages;
