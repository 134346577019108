// Navbar.jsx
import React, { useState } from 'react';
import logo from '../assets/logos/LORZ_LOGO.webp'

const Navbar = ({ activeSection }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className='navbar'> 
      <div className='navbar-container'>
        <div className="logo-container">
            <img src={logo} alt="Logo" className='logo' />
        </div>
        <div className='menu-toggle' onClick={toggleMenu}>
          <div className='hamburger'></div>
        </div>
        <div className={`menu-item-container ${isOpen ? 'open' : ''}`}>
          <ul className="menu">
            <li><a href="#about-us" className={activeSection === "about-us" ? "active" : ""}>Rólunk</a></li>
            <li><a href="#our-values" className={activeSection === "our-values" ? "active" : ""}>Értékeink</a></li>
            <li><a href="#services" className={activeSection === "services" ? "active" : ""}>Szolgáltatások</a></li>
            <li><a href="#packages" className={activeSection === "packages" ? "active" : ""}>Csomagok</a></li>
            <li><a href="#contact" className={activeSection === "contact" ? "active" : ""}>Kapcsolat</a></li>
          </ul>
        </div>
      </div>
      <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(false)}></div>  
    </nav>
  );
};

export default Navbar;
