import React from 'react'
import logo from '../assets/logos/LORZ_LOGO.webp'
import ContactInfo from "../components/ContactInfo";
import name from '../assets/icons/name.webp'
import mail from '../assets/icons/mail.webp'
import tel from '../assets/icons/tel.webp'
import address from '../assets/icons/address.webp'
import facebook from '../assets/icons/facebook.svg'
import instagram from '../assets/icons/instagram.svg'


const Footer = () => {

  const contactData = [
    {label: "", value: "Lorz Kft.", type: "text", icon: name},
    {label: "", value: "+36709429950", type: "phone", icon: tel},
    {label: "", value: "info@lorz.hu", type: "email", icon: mail},
    {label: "", value: "3525 Miskolc, Bodótetői út 1.", type: "text", icon: address}
  ];

  return (
    <footer className="footer-section">
      <div className="footer-container">
      <div className='footer-section-container'>
      <section className="logo-section">
          <div className="logo-wrapper">
            <img src={logo} alt="Lorz Kft. Logo" className="footer-logo" />
            </div>
            <div className='company-description-wrapper'>
            <p className="company-description">„We are a young company always looking for new and creative ideas to help you with our products in your everyday work.”</p>
            <p className="company-executive">Balog Lóránd Ügyvezető</p>
          </div>
          </section>
          <section className='contact-section'>
          <div className="contact-info">
            <h1>Elérhetőségek</h1>
            <ContactInfo data={contactData} />
          </div>
          </section>
          
        
       
        <section className="social-media-section">
          <a href='https://www.instagram.com/lorzkft/' target='_blank' rel='noreferrer' aria-label='Instagram' >
            <img src={instagram} alt="Instagram icon"></img>
          </a>
          <a href='https://www.facebook.com/lorzkft' target='_blank' rel='noreferrer' aria-label='Facebook'>
            <img src={facebook} alt="Facebook icon"></img>
          </a>
        </section>
      </div>
      <div className="section-separator"></div> 
        <section className='copyright-section'>
          <h3>© Lorz Kft. Minden jog fenntartva!</h3>
        </section>
      </div> 
      
    </footer>
  );
}

export default Footer
