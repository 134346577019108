import React from "react";

const Card = ({ logo, title, description, button, contactRef }) => {

  const handleButtonClick = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="card">
      <img src={logo} alt={title} />
      <h2 className="card-title">{title}</h2>
      <p className="card-description">{description}</p>
      {button && <button className="card-button" onClick={handleButtonClick}>{button}</button>}
    </div>
  );
};

export default Card;
