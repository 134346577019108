import React from 'react';
import Card from '../components/Card'; 
import badge from '../assets/icons/badge.webp'
import handshake from '../assets/icons/handshake.webp'
import enlightenment from '../assets/icons/enlightenment.webp'

const OurValues = React.forwardRef((props, ref) => {
  
  return (
    <section id="our-values" ref={ref}>
      <div className='our-values-container'>
        <div className='our-values-content'>
          <article className='our-values-header'>
            <h1 className='header-title'>Miért pont mi?</h1>
            <div className="section-separator orange"></div>
          </article>
          <div className='our-values-cards'>
            <Card 
              logo={badge} 
              title="Magas Minőség" 
              description="Elkötelezettségünk, hogy kiemelkedő, élvonalbeli IT megoldásokat nyújtsunk számodra, amelyek valódi értéket teremtenek."
            />
            <Card 
              logo={handshake} 
              title="Megbízhatóság" 
              description="Megbízható, elkötelezett és tapasztalt munkatársaink mindent megtesznek bármilyen informatikai kérdés, vagy probléma esetén."
            />
            <Card 
              logo={enlightenment} 
              title="Szakmai tudás" 
              description="Kreatív, precíz és szakképzett munkatársaink a legmodernebb informatikai technológiákat alkalmazva segítenek céljaid elérésében"
            />
          </div>
        </div>
      </div>
    </section>
  )
})

export default OurValues;
