import React from "react";

const ContactInfo = ({ data }) => {
  return (
    <div className="our-contacts-container">
      {data.map((item, index) => (
        <h2 key={index}>
          {item.icon && <img src={item.icon} alt={item.label} />}
          {item.label && <span className="mobile-hide">{item.label}: </span>}
          {item.type === "phone" ? (
            <a href={`tel:${item.value}`} style={item.label ? {paddingLeft: "10px"} : {}}>{item.value}</a>
          ) : item.type === "email" ? (
            <a href={`mailto:${item.value}`} style={item.label ? {paddingLeft: "10px"} : {}}>{item.value}</a>
          ) : (
            <span style={item.label ? {paddingLeft: "10px"} : {}}>{item.value}</span>
          )}
        </h2>
      ))}
    </div>
  );
};

export default ContactInfo;
