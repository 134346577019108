import React, { useState, useRef, useEffect } from 'react';
import './styles/scss/main.scss';
import Footer from './components/Footer';
import AboutUs from './sections/AboutUs';
import OurValues from './sections/OurValues';
import Services from './sections/Services';
import Packages from './sections/Packages';
import Contact from './sections/Contact';
import Navbar from './components/Navbar';

const App = () => {
  const [activeSection, setActiveSection] = useState("");
  const sectionRefs = {
    aboutUs: useRef(null),
    ourValues: useRef(null),
    services: useRef(null),
    packages: useRef(null),
    contact: useRef(null)
  };

  useEffect(() => {
    const handleScroll = () => {
      const { aboutUs, ourValues, services,  packages, contact } = sectionRefs;

      if (contact.current.getBoundingClientRect().top <= 400) {
        setActiveSection("contact");
      } else if (packages.current.getBoundingClientRect().top <= 200) {
        setActiveSection("packages");
      } 
      else if (services.current.getBoundingClientRect().top <= 200) {
        setActiveSection("services");
      } else if (ourValues.current.getBoundingClientRect().top <= 200) {
        setActiveSection("our-values");
      } else if (aboutUs.current.getBoundingClientRect().top <= 200) {
        setActiveSection("about-us");
      } 
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let title;
    switch (activeSection) {
      case 'about-us':
        title = 'Rólunk';
        break;
      case 'our-values':
        title = 'Értékeink';
        break;
      case 'services':
        title = 'Szolgáltatások';
        break;
      case 'packages':
        title = 'Csomagok';
        break;
      case 'contact':
        title = 'Kapcsolat';
        break;
      default:
        title = 'Rólunk'; // The title when none of the sections is active
    }
  
    document.title = title + " | Lorz Kft.";
  }, [activeSection]);

  return (
    <>
      <Navbar activeSection={activeSection} contactRef={sectionRefs.contact} />
      <AboutUs ref={sectionRefs.aboutUs} contactRef={sectionRefs.contact} />
      <OurValues ref={sectionRefs.ourValues} contactRef={sectionRefs.contact} />
      <Services ref={sectionRefs.services} contactRef={sectionRefs.contact} />
      <Packages ref={sectionRefs.packages} contactRef={sectionRefs.contact} />
      <Contact ref={sectionRefs.contact} contactRef={sectionRefs.contact} />
      <Footer />
    </>
  );
};


export default App;
