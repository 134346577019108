import React from 'react'
import Card from '../components/Card'
import asset1 from '../assets/icons/lorz-szolg-adat.svg'
import asset2 from '../assets/icons/lorz-szolg-alkalmazas.svg'
import asset3 from '../assets/icons/lorz-szolg-webshop.svg'
import asset4 from '../assets/icons/lorz-szolg-mobilalk.svg'
import asset5 from '../assets/icons/lorz-szolg-weblap.svg'
import asset6 from '../assets/icons/lorz-szolg-kisvall.svg'
import Counter from '../components/Counter'



const data = [
  {title: "Elégedett ügyfél", end: 16},
  {title: "Projekt", end: 57},
  {title: "Tapasztalat", end: 15, showPlus: true, metric: ' év'},
  {title: "Fejlesztő", end: 6}
];



const Services = React.forwardRef((props, ref) => {

  const { contactRef } = props;

  return (
    <section id="services" ref={ref}>
    <div className='services-container'>
      <div className='services-content'>
        <article className='services-header'>
          <h1 className='header-title'>Szolgáltatások</h1>
          <div className="section-separator blue"></div>
        </article>
        <div className='services-cards'>
          <Card 
            logo={asset1} 
            title="Weblap fejlesztés" 
            description="Akár egy alap weboldalt, akár egy landing oldalt szeretnél vállalkozásod számára, mi mindenben segítünk! A weboldalt egyedi rendszerekkel, és egyedi arculattal látjuk el."
            button="Érdekel"
            contactRef={contactRef}
          />
          <Card 
            logo={asset2} 
            title="Kisvállalati rendszerek" 
            description="Egyedi fejlesztésű CRM, illetve termelésirányítási kisvállalati szoftvereinkkel megkönnyítjük az ipari tevékenységet, gyártást folytató vállalkozásod mindennapjait."
            button="Érdekel"
            contactRef={contactRef}
          />
          <Card 
            logo={asset3} 
            title="Webshop készítés" 
            description="Egy webshop elkészítése során figyelembe vesszük a mai modern trendeket, és a legjobb technológiákat annak érdekében, hogy funkciójában, és kinézetében is minőséget kapj."
            button="Érdekel"
            contactRef={contactRef}
          />
          <Card 
            logo={asset4} 
            title="Mobilalkalmazás fejlesztés" 
            description="Szeretnél szintet lépni vállalkozásoddal? A legújabb technológiákat felhasználva készítjük el egyedi mobil alkalmazásodat iOS, Android, illetve platformfüggetlen felületekre."
            button="Érdekel"
            contactRef={contactRef}
          />
          <Card 
            logo={asset5} 
            title="Alkalmazásfejlesztés" 
            description="Személyre szabott szolgáltatásunk keretein belül natív, illetve cross-platform technológiák felhasználásával alakítjuk ki az általad megálmodott alkalmazást bármilyen platformra."
            button="Érdekel"
            contactRef={contactRef}
          />
          <Card 
            logo={asset6} 
            title="Adatközponti és felhőautomatizáció" 
            description="Egy hatékony belső rendszer elengedhetetlen manapság egy vállalat gördülékeny működéséhez. Egyedi adatközponti és felhőautomatizációs rendszerek lefejlesztésével támogatjuk mindezt."
            button="Érdekel"
            contactRef={contactRef}
          />
        </div>
      </div>
      <div className="data-display-outer">
      <div className="data-display-container">
        {data.map(item => (
          <Counter key={item.title} title={item.title} end={item.end} showPlus={item.showPlus} metric={item.metric} />
        ))}
      </div>
    </div>
    </div>
  </section>
  )
})

export default Services