import React from 'react'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const Counter = ({ title, end, showPlus, metric }) => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger just once
        threshold: 1, // 100% of the element is in view
      });
  
    return (
        <div className="data-display-item" ref={ref}>
      <h2 id={`${title.replace(" ", "")}Count`} className="data-display-number">
        <CountUp start={0} end={inView ? end : 0} />{showPlus && <span>+</span>}{metric && <span>{metric}</span>}
      </h2>
      <p className="data-display-title">{title}</p>
    </div>
    )
  }

export default Counter

