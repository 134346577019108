import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga4'

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASURMENT_ID)

root.render(
    <App />
);