import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [form, setForm] = useState({
    isSending: false,
    isSent: false,
    error: false,
  });

  const [showThankYou, setShowThankYou] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [formData, setFormData] = useState(null);

  const svgRef = useRef();
  const formRef = useRef();
  const recaptchaRef = useRef();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
  });

  const fieldHasValue = (fieldName) => {
    return getValues(fieldName) !== "";
  };

  const watchedFields = watch(["name", "phoneNumber", "email", "message"]);

  useEffect(() => {
    if (form.isSending) {
      svgRef.current.classList.add("progress");
    }
  }, [form.isSending]);

  useEffect(() => {
    if (form.isSent) {
      svgRef.current.classList.remove("progress");
      svgRef.current.classList.add("ready");
      setTimeout(() => {
        setShowThankYou(true);
        svgRef.current.classList.remove("progress");
        svgRef.current.classList.remove("ready");
        reset();
      }, 2000);
    }
  }, [form.isSent]);

  useEffect(() => {
    const setFormHeight = () => {
      const initialHeight = formRef.current.getBoundingClientRect().height;
      formRef.current.style.minHeight = `${initialHeight}px`;
    };

    setFormHeight();
  }, []);

  const handleNewMessage = (event) => {
    event.preventDefault();
    setShowThankYou(false);
    setForm({
      isSending: false,
      isSent: false,
      error: false,
    });

    ["name", "phoneNumber", "email", "message"].forEach((field) =>
      setValue(field, "")
    );
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setForm((prevForm) => ({ ...prevForm, isSending: true, error: null }));
    setFormData(data);
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const onReCAPTCHAChange = (token) => {
    setRecaptchaToken(token);
    if (token && formData) {
      submitForm(formData);
    }
  };

  const submitForm = async (data) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://localhost:7053';
      const apiKey = process.env.REACT_APP_API_KEY;
      const fullApiUrl = `${apiUrl}/api/ContactUs/SendConfirmation`;
      const response = await axios.post(fullApiUrl, { ...data, recaptchaToken }, {
        headers: { 'ApiKey': apiKey }
      });

      setForm((prevForm) => ({ ...prevForm, isSent: true, isSending: true }));

      setRecaptchaToken("");
      setFormData(null);
      reset();
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    } catch (error) {
      console.error("Probléma történt az axios művelet során:", error);
      let errorMessage = "Váratlan hiba történt. Kérjük, próbálja meg újra.";
      if (error.response) {
        errorMessage =
          error.response.data.message || "Hiba történt a szerveren.";
      } else if (error.request) {
        errorMessage = "Nem érkezett válasz a szervertől.";
      }

      setForm((prevForm) => ({
        ...prevForm,
        isSending: false,
        error: errorMessage,
      }));
    }
  };

  return (
    <div
      ref={formRef}
      className={`contact-form-container ${form.isSending ? "sending" : ""} ${
        form.isSent ? "sent" : ""
      }`}
    >
      {form.error && (
        <div className="error-container">
          <p className="error-message">
            Sajnáljuk, hiba történt a kérelme feldolgozása során. Kérjük,
            próbálja meg újra később.
          </p>
          <p className="contact-suggestion">
            Amennyiben a probléma továbbra is fennáll, kérjük lépjen kapcsolatba
            velünk az alábbi elérhetőségek egyikén:
          </p>
          <div className="contact-info">
            <p>
              <i className="icon tel"></i>Telefon:{" "}
              <a href="tel:+36709429950">+36 70 942 9950</a>
            </p>
            <p>
              <i className="icon mail"></i>E-mail:{" "}
              <a href="mailto:info@lorz.hu">info@lorz.hu</a>
            </p>
          </div>
          <div className="button-wrapper">
            <button className="button" onClick={handleNewMessage}>
              <span className="text">Új üzenetet küldök</span>
            </button>
          </div>
        </div>
      )}
      {!form.error && (
        <>
          {showThankYou ? (
            <div className="thank-you-container">
              <svg
                id="check"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                className="ready"
              >
                <circle id="circle" cx="50" cy="50" r="46" fill="transparent" />
                <polyline
                  id="tick"
                  points="25,55 45,70 75,33"
                  fill="transparent"
                />
              </svg>
              <p className="thank-you">Köszönjük az üzenetét megkaptuk.</p>
              <p className="looking-for-you">
                Kollégánk hamarosan keresni fogja Önt a megadott elérhetőségek
                egyikén.
              </p>
              <div className="button-wrapper">
                <button className="button" onClick={handleNewMessage}>
                  <span className="text">Új üzenetet küldök</span>
                </button>
              </div>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="name">Név *</label>
                <input
                  {...register("name", {
                    required: "A mező kitöltése kötelező",
                    minLength: {
                      value: 4,
                      message: "Minimum 4 karakter megadása kötelező",
                    },
                  })}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Név..."
                  value={watchedFields.name}
                  onChange={(e) => setValue("name", e.target.value)}
                  className={
                    errors.name && !fieldHasValue("name") ? "error" : ""
                  }
                />
                {errors.name && (
                  <span className="error-message">{errors.name.message}</span>
                )}
                <label htmlFor="email">Email cím *</label>
                <input
                  {...register("email", {
                    required: "A mező kitöltése kötelező",
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Érvénytelen email cím",
                    },
                  })}
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email cím..."
                  value={watchedFields.email}
                  onChange={(e) => setValue("email", e.target.value)}
                  className={
                    errors.email && !fieldHasValue("email") ? "error" : ""
                  }
                />
                {errors.email && (
                  <span className="error-message">{errors.email.message}</span>
                )}

                <label htmlFor="phoneNumber">Telefonszám *</label>
                <input
                  {...register("phoneNumber", {
                    required: "A mező kitöltése kötelező",
                    validate: (value) => {
                      const pattern = /^[0-9+]*$/;
                      if (!pattern.test(value)) {
                        return "Csak számokat és '+' karaktert írhat be";
                      } else if (value.length < 4) {
                        return "Minimum 4 szám megadása kötelező";
                      }
                      return true;
                    },
                  })}
                  type="phoneNumber"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Telefonszám..."
                  value={watchedFields.tel}
                  onChange={(e) => setValue("phoneNumber", e.target.value)}
                  className={
                    errors.phoneNumber && !fieldHasValue("phoneNumber")
                      ? "error"
                      : ""
                  }
                />
                {errors.phoneNumber && (
                  <span className="error-message">
                    {errors.phoneNumber.message}
                  </span>
                )}

                <label htmlFor="message">Üzenet *</label>
                <textarea
                  {...register("message", { required: true })}
                  id="message"
                  name="message"
                  placeholder="Üzenet..."
                  value={watchedFields.message}
                  onChange={(e) => setValue("message", e.target.value)}
                  className={
                    errors.message && !fieldHasValue("message") ? "error" : ""
                  }
                />
                {errors.message && (
                  <span className="error-message">
                    A mező kitöltése kötelező
                  </span>
                )}
                <input
                  type="submit"
                  className={`button send-button ${
                    form.isSending ? "clicked" : ""
                  }`}
                  value={"Küldés"}
                />
              </form>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                size="invisible"
                onChange={onReCAPTCHAChange}
              />
            </>
          )}
        </>
      )}
      {!form.error && (
        <>
          <svg
            ref={svgRef}
            id="check"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <circle id="circle" cx="50" cy="50" r="46" fill="transparent" />
            <polyline id="tick" points="25,55 45,70 75,33" fill="transparent" />
          </svg>
        </>
      )}
    </div>
  );
};

export default ContactForm;
